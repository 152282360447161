import React from "react";
import { servicesConfig } from "../configs/servicesConfig";
import { useTranslation } from "react-i18next";

function Services() {
  const { t: translate } = useTranslation();

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div
        className="relative w-full h-[60vh] flex items-center justify-center text-center bg-cover bg-center"
        style={{
          backgroundImage: "url('/assets/images/services-section.jpeg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60" />
        <h1 className="relative text-5xl font-bold text-white">
          {translate("services_page_title")}
        </h1>
      </div>

      {/* Services Section */}
      <section className="py-20 px-6 md:px-20">
        <h2 className="text-4xl font-bold text-center mb-12">
          {translate("services_page_subtitle")}
        </h2>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-12">
          {servicesConfig.map((service, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center text-white"
            >
              {/* Image Section */}
              <div className="w-full md:w-[320px] h-[200px] flex-shrink-0">
                <img
                  src={service.image}
                  alt={translate(service.title)}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>

              {/* Text Section */}
              <div className="md:ml-6 mt-6 lg:mt-0 text-left">
                <h3 className="text-2xl font-semibold">
                  {translate(service.title)}
                </h3>
                {service.descriptions.map((description) => {
                  return <p className="mt-2">• {translate(description)}</p>;
                })}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Services;
