import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { galleryPhotosConfig } from "../configs/galleryPhotosConfig";

function Gallery() {
  const { t: translate } = useTranslation();

  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div
        className="relative w-full h-[60vh] flex items-center justify-center text-center bg-cover bg-center"
        style={{
          backgroundImage: "url('/assets/images/gallery-section.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60" />
        <h1 className="relative text-5xl font-bold text-white">
          {translate("gallery_page_title")}
        </h1>
      </div>

      {/* Gallery Section */}
      <section className="py-20 px-6 md:px-20">
        <div className="container mx-auto p-2">
          <div className="flex flex-wrap justify-center gap-4">
            {galleryPhotosConfig.map((image, index) => (
              <button
                key={index}
                className="w-full md:w-1/3 lg:w-1/4 overflow-hidden rounded-lg shadow-lg"
                onClick={() =>
                  setSelectedImage({ image: image.src, idx: index })
                }
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-[300px] object-cover transition-transform duration-300 hover:scale-105"
                />
              </button>
            ))}
          </div>
        </div>

        {selectedImage && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center">
            <p
              className="fixed top-28 right-5 text-white text-2xl hover:cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 transition-all ease-in-out rounded-full px-4 py-2"
              onClick={() => setSelectedImage(null)}
            >
              X
            </p>

            <button
              className="absolute left-20 text-white text-4xl hover:cursor-pointer bg-gray-400 bg-opacity-20 hover:bg-gray-500 hover:bg-opacity-20 transition-all ease-in-out rounded-full px-5 py-2"
              onClick={() => {
                const prevIndex = selectedImage.idx - 1;

                if (prevIndex >= 0) {
                  setSelectedImage({
                    image: galleryPhotosConfig[prevIndex].src,
                    idx: prevIndex,
                  });
                }
              }}
            >
              &#10094;
            </button>

            <img
              src={selectedImage.image}
              alt="Selected"
              className="max-w-[85%] max-h-[65%]"
            />

            <button
              className="absolute right-20 text-white text-4xl hover:cursor-pointer bg-gray-400 bg-opacity-20 hover:bg-gray-500 hover:bg-opacity-20 transition-all ease-in-out rounded-full px-5 py-2"
              onClick={() => {
                const nextIndex = selectedImage.idx + 1;
                if (nextIndex < galleryPhotosConfig.length) {
                  setSelectedImage({
                    image: galleryPhotosConfig[nextIndex].src,
                    idx: nextIndex,
                  });
                }
              }}
            >
              &#10095;
            </button>
          </div>
        )}
      </section>
    </div>
  );
}

export default Gallery;
