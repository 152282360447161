import React from "react";
import CustomHero from "../components/PagesComponents/CustomHero";
import ServicesSection from "../components/PagesComponents/ServicesSection";
import AboutUsSection from "../components/PagesComponents/AboutUsSection";
import ContactUsSection from "../components/PagesComponents/ContactUsSection";
import AccreditationsSection from "../components/PagesComponents/AccreditationsSection";

const Home = () => {
  return (
    <div className="min-h-screen">
      <CustomHero />
      <AboutUsSection />
      <ServicesSection title="Servicii" />
      <AccreditationsSection />
      {/* <TestimonialSection /> */}
      <ContactUsSection />
    </div>
  );
};

export default Home;
