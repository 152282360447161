import React from "react";
import { useTranslation } from "react-i18next";
import GoogleMaps from "../components/GoogleMaps";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import Mailto from "../components/Mailto";

import { clientConfig, regionalContacts } from "../configs/projectConfig";
import { contactConfig } from "../configs/contactConfig";
import KTSVG from "../utils/KTSVG";

function Contact() {
  const { t: translate } = useTranslation();

  return (
    <div>
      <HeroComponent
        height={650}
        title={translate("contact_title")}
        backgroundImage={toAbsoluteUrl("/assets/images/contact.jpg")}
        wrapperClassName="bg-top"
      />

      <div className="p-5 sm:p-10 lg:p-20">
        {/* Juridical Info */}
        <div className="bg-gray-50 max-w-7xl mx-auto p-4 sm:p-10 rounded-xl">
          <h3 className="text-primary-color text-2xl font-semibold mb-4">
            {translate("contact_juridical_information")}
          </h3>
          <p className="text-sm xl:text-base font-medium">
            Nr. Ord. Reg. Com.:{" "}
            <span className="font-semibold">{clientConfig.orderNumber}</span>
          </p>
          <p className="text-sm xl:text-base font-medium">
            CUI: <span className="font-semibold">{clientConfig.CUI}</span>
          </p>
          <p className="text-sm xl:text-base font-medium">
            CIF: <span className="font-semibold">{clientConfig.CIF}</span>
          </p>
          <p className="text-sm xl:text-base font-medium flex items-center mt-4">
            <i
              className="fa fa-phone text-primary-color mr-2 text-lg"
              aria-hidden="true"
            />
            {clientConfig.phone} / {clientConfig.mobile}
          </p>
          <p className="text-sm xl:text-base font-medium flex items-center">
            <i
              className="fa fa-envelope text-primary-color mr-2 text-lg"
              aria-hidden="true"
            />
            {clientConfig.email}
          </p>
          <p className="text-sm xl:text-base font-medium flex items-center">
            <i
              className="fa fa-map-marker text-primary-color mr-2 text-lg"
              aria-hidden="true"
            />
            {clientConfig.address}
          </p>
        </div>

        {/* Positions held */}
        <div className="mt-10 flex justify-around flex-wrap max-w-7xl mx-auto">
          {contactConfig.map((contact) => {
            return (
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center bg-gray-900 text-white px-3 p-4 my-2 rounded-md shadow-sm">
                  <KTSVG
                    path={toAbsoluteUrl(contact.icon)}
                    svgClassName="w-[80px] h-[80px]"
                    fill="white"
                  />
                  <div className="ml-3 max-w-[280px]">
                    <p className="font-semibold">
                      {translate(contact.positionHeld)}
                    </p>
                    <p>{contact?.name}</p>
                    <p>{contact?.phone}</p>
                    <p>{contact?.email}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Regional Contacts */}
        <div className="bg-gray-900 text-white max-w-7xl mx-auto mt-10 p-4 sm:p-10 rounded-xl">
          <h3 className="text-grey-50 text-2xl font-semibold mb-4">
            {translate("contact_regional_contacts")}
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {regionalContacts.map((contact, index) => (
              <div
                key={index}
                className="bg-white text-black shadow-sm p-4 rounded-lg"
              >
                <h4 className="text-primary-color text-lg font-semibold mb-2">
                  {contact.location}
                </h4>
                <p className="text-sm xl:text-base font-medium flex items-center">
                  <i
                    className="fa fa-envelope text-primary-color mr-2"
                    aria-hidden="true"
                  />
                  {contact.email}
                </p>
                <p className="text-sm xl:text-base font-medium flex items-center">
                  <i
                    className="fa fa-phone text-primary-color mr-2"
                    aria-hidden="true"
                  />
                  {contact.phone}
                </p>
              </div>
            ))}
          </div>
        </div>

        <Mailto
          email={clientConfig.email}
          className="mt-6 block text-center text-primary-color font-semibold max-w-7xl mx-auto"
        >
          {translate("contact_us_cta")}
        </Mailto>

        {/* Google Maps */}
        <div className="max-w-7xl mx-auto mt-5">
          <GoogleMaps
            height={400}
            width={"100%"}
            src={clientConfig.mapsUrl}
            title="map-location"
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
