import React from "react";
import { useTranslation } from "react-i18next";
import { servicesConfig } from "../../configs/servicesConfig";
import KTSVG from "../../utils/KTSVG";
import { toAbsoluteUrl } from "../../helpers/assetHelper";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  const { t: translate } = useTranslation();

  return (
    <section className="py-20 text-center px-6 lg:px-20 bg-gray-900 text-white ">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-5xl mx-auto">
        {servicesConfig.map((service, idx) => (
          <Link
            to={"/services"}
            key={idx}
            className="flex flex-row items-left p-6 border border-gray-700 bg-transparent hover:bg-gray-800 hover:bg-opacity-70 rounded-lg text-left text-white transition-all ease-in-out duration-300"
          >
            <KTSVG
              path={toAbsoluteUrl(service.icon)}
              svgClassName="w-[70px] h-[50px]"
              fill="white"
            />
            <div className="ml-2">
              <h3 className="text-xl font-semibold mb-3">
                {translate(service.title)}
              </h3>
              <div>
                {service.descriptions &&
                  service.descriptions?.map((description) => {
                    return (
                      <p className="text-sm text-left">
                        • {translate(description)}
                      </p>
                    );
                  })}
              </div>
            </div>
            <p className="text-sm">{translate(service.description)}</p>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
