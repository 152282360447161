import React, { useEffect, useState } from "react";
import SiteLang from "./SiteLang";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useRoutesHelper from "../hooks/useRoutesHelper";
import { toAbsoluteUrl } from "../helpers/assetHelper";

const navbarPages = [
  { name: "navbar_home", to: "/" },
  { name: "navbar_contact", to: "contact" },
  { name: "navbar_services", to: "services" },
  // { name: "navbar_gallery", to: "gallery" },
  { name: "navbar_covering", to: "covering" },
];

function Navbar() {
  const { t: translate } = useTranslation();
  const { currentUrl } = useRoutesHelper();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const opacity = Math.min(1, scrollY / 300);
  const navbarBackground = `rgba(255, 255, 255, ${0 + opacity * 1})`;

  const textColor = `rgb(${255 - opacity * 255}, ${255 - opacity * 255}, ${
    255 - opacity * 255
  })`;

  const [activeLink, setActiveLink] = useState("/");

  useEffect(() => {
    const paramsStartingIndex = currentUrl.indexOf("/");
    const getSlicedString = () =>
      paramsStartingIndex > 0
        ? currentUrl.substring(0, paramsStartingIndex)
        : currentUrl;

    setActiveLink(`${getSlicedString()}`);
  }, [currentUrl]);

  const [showNav, setShowNav] = useState(false);
  const handleShowNav = () => setShowNav(!showNav);

  return (
    <nav
      className={`fixed top-0 w-full z-50 transition-all ease-in-out duration-500 shadow-md`}
      style={{ backgroundColor: navbarBackground }}
    >
      <div
        className="hidden xl:flex max-w-7xl mx-auto flex-row justify-between items-center py-4 px-6"
        style={{ color: textColor }}
      >
        <Link to="/" className="w-[150px]">
          <img
            src={toAbsoluteUrl("/assets/ATSAssets/ats-logo.png")}
            alt="ATS-logo"
            className=""
            // style={{
            //   opacity: scrollY > 100 ? 1 : 0,
            //   pointerEvents: scrollY > 100 ? "auto" : "none",
            // }}
          />

          {/* <img
            src={toAbsoluteUrl("/assets/ATSAssets/ats-logo-white.png")}
            alt="ATS-logo"
            className="absolute top-5 left-0 hidden xl:flex w-[80px] rounded-md transition-opacity duration-500"
            style={{
              opacity: scrollY > 100 ? 0 : 1,
              pointerEvents: scrollY > 100 ? "none" : "auto",
            }}
          /> */}
        </Link>
        <ul className="hidden xl:flex md:flex-row items-center">
          {navbarPages.map((navbarPage) => (
            <Link
              key={navbarPage.to}
              to={navbarPage.to}
              className={`navbar-page-btn group`}
              style={{ color: textColor }}
            >
              {translate(navbarPage.name)}
              {navbarPage.to === activeLink ? (
                <span
                  className="block max-w-full transition-all duration-500 h-0.5 bg-primary-light-color"
                  style={{ backgroundColor: textColor }}
                />
              ) : (
                <span
                  className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-primary-light-color"
                  style={{ backgroundColor: textColor }}
                />
              )}
            </Link>
          ))}
          <SiteLang
            wrapperClassName="px-2 py-2 h-auto hidden xl:flex"
            dropdownClassName={`flex flex-col bg-secondary-color bg-opacity-30 rounded-md px-2 py-2 absolute ${
              scrollY > 50 ? "top-[70px]" : "top-[60px]"
            } cursor-pointer ease-out duration-500`}
          />
        </ul>
      </div>
      <div className="xl:hidden flex justify-between items-center px-5 py-3">
        <Link to="/" className="relative w-[130px]">
          <img
            src={toAbsoluteUrl("/assets/ATSAssets/ATS-logo.png")}
            alt="ATS-logo"
            className=""
            // style={{
            //   opacity: scrollY > 100 ? 1 : 0,
            //   pointerEvents: scrollY > 100 ? "auto" : "none",
            // }}
          />

          {/* <img
            src={toAbsoluteUrl("/assets/ATSAssets/ats-logo-white.png")}
            alt="ATS-logo"
            className="absolute top-3 left-0 flex w-[80px] rounded-md transition-opacity duration-500"
            style={{
              opacity: scrollY > 100 ? 0 : 1,
              pointerEvents: scrollY > 100 ? "none" : "auto",
            }}
          /> */}
        </Link>

        {/* Mobile Menu Button */}
        <div onClick={handleShowNav}>
          {!showNav ? (
            <AiOutlineMenu
              size={45}
              className="cursor-pointer py-2"
              style={{ color: textColor }}
            />
          ) : (
            <AiOutlineClose
              size={45}
              className="cursor-pointer py-2"
              style={{ color: textColor }}
            />
          )}
        </div>
      </div>

      {showNav && (
        <div className={"bg-white backdrop-blur-sm h-screen w-full"}>
          <SiteLang
            onClickLanguage={handleShowNav}
            wrapperClassName="flex justify-end p-5 border-b border-gray-300"
            dropdownClassName="flex flex-row-reverse p-5 absolute right-[50px] top-0"
            dropdownImageClassName="ml-2"
          />
          <ul className="flex flex-col w-full">
            {navbarPages.map((navbarPage) => (
              <Link
                onClick={handleShowNav}
                key={navbarPage.to}
                to={navbarPage.to}
                className={`text-black ${
                  navbarPage.to === activeLink
                    ? "navbar-responsive-active-page-btn"
                    : "navbar-responsive-page-btn"
                }`}
                style={{ color: "black" }}
              >
                {translate(navbarPage.name)}
              </Link>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
