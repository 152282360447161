export const servicesConfig = [
  {
    title: "services_national_coverage_title",
    descriptions: [
      "services_transit_vans_description",
      "services_specialized_transport_description",
      "services_licensed_description",
    ],
    icon: "/assets/transportVan.svg",
    image: "/assets/images/transport-box.jpg",
  },
  {
    title: "services_freight_transfer_nodes",
    descriptions: [
      "services_optimized_distribution_description",
      "services_transfer_hubs_description",
      "services_fast_handling_description",
    ],
    icon: "/assets/transit.svg",
    image: "/assets/images/transport-cold.jpg",
  },
  {
    title: "services_controlled_environment_title",
    descriptions: [
      "services_controlled_environment_temperature_low",
      "services_controlled_environment_temperature_high",
      "services_controlled_environment_compliance",
    ],
    icon: "/assets/controlled-environment.svg",
    image: "/assets/images/controlled-environment.jpg",
  },
  {
    title: "services_pharmaceutical_transport_title",
    descriptions: [
      "services_pharmaceutical_transport_medicines",
      "services_pharmaceutical_transport_supplements",
      "services_pharmaceutical_transport_cosmetics",
    ],
    icon: "/assets/pharmaceutical-products.svg",
    image: "/assets/images/transport-package.jpg",
  },
  {
    title: "services_document_transport_title",
    descriptions: [
      "services_document_transport_secure_delivery",
      "services_document_transport_confidential_handling",
      "services_document_transport_safety_compliance",
    ],
    icon: "/assets/documents-transport.svg",
    image: "/assets/images/documents-transport.jpg",
  },
  {
    title: "services_payment_collection_title",
    descriptions: [
      "services_payment_collection_secure_processing",
      "services_payment_collection_auto_recording",
      "services_payment_collection_flexible_options",
    ],
    icon: "/assets/payment-services.svg",
    image: "/assets/images/payment.jpg",
  },
];
