import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { partnersConfig } from "../../configs/projectConfig";
import { toAbsoluteUrl } from "../../helpers/assetHelper";

function PartnersSlider({ title, className }) {
  return (
    <div className={`select-none max-w-full ${className} md:-mt-[150px]`}>
      <div className="max-full mx-auto">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          effect="fade"
          loop={true}
          draggable
          breakpoints={{
            // when window width is >= 425px
            425: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 5,
              spaceBetween: 0,
            },
          }}
        >
          {partnersConfig.slice(1).map((partner, idx) => {
            return (
              <SwiperSlide key={idx} className="bg-gray-50 drop-shadow-sm">
                <div className="flex flex-col h-[150px] justify-center items-center text-black">
                  <img
                    alt={`technology-${idx}`}
                    className="h-[80px] w-[100px] object-contain"
                    src={toAbsoluteUrl(partner.icon)}
                  />
                  <a
                    className="text-center text-sm font-semibold mt-4 text-blue-600"
                    href={partner.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {partner.name}
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default PartnersSlider;
