export const clientConfig = {
  name: "AHEAD TRANSLOG SOLUTIONS srl",
  address:
    "Str. Prelungirea Garii Catelu nr. 25, parter, cam. P25 / P26, sat Caldararu, com. Cernica, jud. Ilfov 077037",
  orderNumber: "J23 / 3534 / 29.05.2023",
  CUI: "37488068",
  CIF: "RO37488068",
  email: "contact@ahead-translog.ro",
  phone: "+40 31 433 8676",
  mobile: "+40 748 581 171",
  mapsUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1010!2d26.2194185!3d44.4260373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fb8c30c40b79%3A0xd4f6f6f6891c5a95!2sStrada%20Prelungirea%20G%C4%83rii%20C%C4%83%C8%9Belu%2025%2C%20Cernica%20077035!5e0!3m2!1sro!2sro!4v1722334289073!5m2!1sro!2sro",
};

export const regionalContacts = [
  {
    location: "Bucuresti",
    email: "bucuresti@ahead-translog.ro",
    phone: "0728 220 168",
  },
  {
    location: "Buzau",
    email: "buzau@ahead-translog.ro",
    phone: "0748 842 828",
  },
  {
    location: "Cluj / Oradea / V.Chioarului",
    email: "cluj@ahead-translog.ro",
    phone: "0748 916 159",
  },
  {
    location: "Constanta",
    email: "constanta@ahead-translog.ro",
    phone: "0748 958 376",
  },
  {
    location: "Craiova",
    email: "craiova@ahead-translog.ro",
    phone: "0771 243 512",
  },
  {
    location: "Orastie",
    email: "orastie@ahead-translog.ro",
    phone: "0773 883 129",
  },
  {
    location: "Roman / Miercurea-Ciuc",
    email: "roman@ahead-translog.ro",
    phone: "0743 633 153",
  },
];

export const partnersConfig = [
  {
    label: "projects_page_technologies_filter",
    icon: "/assets/ATSAssets/PartnersIcons/ssnt-logo.svg",
    name: "SSNT",
    link: "https://ssnt.ro/",
  },
  {
    label: "React",
    icon: "/assets/ATSAssets/PartnersIcons/2afarm-logo.svg",
    name: "2A Farm",
    link: "https://2afarm.ro/ ",
  },
  {
    label: "React-Native",
    icon: "/assets/ATSAssets/PartnersIcons/pharmahead-logo.jpg",
    name: "Pharmahead",
    link: "https://pharmahead.ro/",
  },
  {
    label: "Laravel",
    icon: "/assets/ATSAssets/PartnersIcons/remedia-logo.svg",
    name: "Remedia",
    link: "https://corporate.remedia.ro/",
  },
  {
    label: "MySql",
    icon: "/assets/ATSAssets/PartnersIcons/pharma-z-logo.svg",
    name: "Pharma-z",
    link: "https://pharma-z.ro/",
  },
  {
    label: "projects_page_technologies_filter",
    icon: "/assets/ATSAssets/PartnersIcons/ssnt-logo.svg",
    name: "SSNT",
    link: "https://ssnt.ro/",
  },
  {
    label: "React",
    icon: "/assets/ATSAssets/PartnersIcons/2afarm-logo.svg",
    name: "2A Farm",
    link: "https://2afarm.ro/ ",
  },
  {
    label: "React-Native",
    icon: "/assets/ATSAssets/PartnersIcons/pharmahead-logo.jpg",
    name: "Pharmahead",
    link: "https://pharmahead.ro/",
  },
];

export const accreditationsConfig = [
  {
    name: "accreditations_arr_title",
    icon: "/assets/ATSAssets/Accreditations/arr.jpg",
    link: "https://www.arr.ro/",
  },
  {
    name: "accreditations_isctr_title",
    icon: "/assets/ATSAssets/Accreditations/isctr.png",
    link: "http://www.isctr-mt.ro/",
  },
  {
    name: "accreditations_anmdmr_title",
    icon: "/assets/ATSAssets/Accreditations/anmdmr.jpg",
    link: "https://www.anm.ro/",
  },
];
