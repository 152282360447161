export const galleryPhotosConfig = [
  { src: "/assets/images/GalleryImages/gallery1.jpg", alt: "transport1" },
  { src: "/assets/images/GalleryImages/gallery2.jpg", alt: "transport2" },
  { src: "/assets/images/GalleryImages/gallery3.jpg", alt: "transport3" },
  { src: "/assets/images/GalleryImages/gallery4.jpg", alt: "transport4" },
  { src: "/assets/images/GalleryImages/gallery5.jpg", alt: "transport5" },
  { src: "/assets/images/GalleryImages/gallery6.jpg", alt: "transport6" },
  { src: "/assets/images/GalleryImages/gallery7.jpg", alt: "transport6" },
  { src: "/assets/images/GalleryImages/gallery8.jpg", alt: "transport6" },
];
