import "./App.css";
import "swiper/css";
import "swiper/css/bundle";

import "./lang/i18n";

import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import ScrollToTop from "./utils/ScrollToTop";
import Footer from "./components/Footer";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy";
import Navbar from "./components/Navbar";
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import Covering from "./pages/Covering";

function App() {
  return (
    <div className="font-montserrat max-w-[2600px] bg-[rgb(249, 250, 250)] mx-auto ">
      <BrowserRouter>
        <ScrollToTop />

        <Navbar />

        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/covering" element={<Covering />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />

            <Route path="*" element={<Home />} />
          </Routes>
        </div>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
