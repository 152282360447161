import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

const isDebuggerMode = process.env.NODE_ENV !== "production";

export const store = configureStore({
  reducer: {},
  devTools: isDebuggerMode,
  middleware: (getDefaultMiddleware) => {
    if (isDebuggerMode) {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});
