export const contactConfig = [
  {
    positionHeld: "contact_director_position",
    name: "Bogdan Stefan Negoescu",
    phone: "+40 748 581 171",
    email: "bogdan.negoescu@ahead-translog.ro",
    icon: "/assets/ceo.svg",
  },
  {
    positionHeld: "contact_transport_manager_position",
    name: "Victor Nicolae",
    phone: "+40 739 861 751",
    email: "victor.nicolae@ahead-translog.ro",
    icon: "/assets/manager.svg",
  },
  {
    positionHeld: "contact_pharmacist_position",
    icon: "/assets/pharmacist.svg",
  },
];
