import React from "react";
import { useTranslation } from "react-i18next";
import { accreditationsConfig } from "../../configs/projectConfig";

const AccreditationsSection = () => {
  const { t: translate } = useTranslation();

  return (
    <section className="py-20 text-center px-6 lg:px-20 bg-gray-100">
      <h2 className="text-4xl font-bold mb-4">
        {translate("accreditations_section_title")}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {accreditationsConfig.map((accreditation, index) => (
          <div
            key={index}
            className="flex items-center min-h-[180px] mt-5 bg-white p-6 rounded-lg shadow-md text-left "
          >
            <div className="flex items-center">
              <img
                src={accreditation.icon}
                alt={accreditation.name}
                className="w-20 h-20 mr-4"
              />

              <a
                href={accreditation.link}
                className="text-gray-500 hover:text-gray-700 text-sm font-semibold transition-all ease-in-out duration-200"
                target="_blank"
                rel="noreferrer"
              >
                {translate(accreditation.name)}
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AccreditationsSection;
