import React from "react";
import { useTranslation } from "react-i18next";

const coveringData = [
  {
    nod: "Bucuresti",
    zona: "BUC",
    arie: "Bucuresti, Ilf, Giurgiu, Teleorman, Prahova, Dambovita, Calarasi (partial), Ialomita (partial)",
  },
  {
    nod: "Buzau",
    zona: "BZU",
    arie: "Buzau, Vrancea, Galati, Braila, Ialomita (partial)",
  },
  { nod: "Cluj", zona: "CLJ", arie: "Cluj, Salaj, Mures, Bistrita-Nasaud" },
  {
    nod: "Constanta",
    zona: "CTA",
    arie: "Constanta, Tulcea, Ialomita (partial), Calarasi (partial)",
  },
  {
    nod: "Craiova",
    zona: "CRV",
    arie: "Dolj, Olt, Arges, Valcea, Mehedinti, Gorj",
  },
  {
    nod: "Miercurea – Ciuc",
    zona: "MRC",
    arie: "Harghita, Covasna, Brasov (doar orasul)",
  },
  { nod: "Oradea", zona: "BIH", arie: "Bihor" },
  {
    nod: "Orastie",
    zona: "ORS",
    arie: "Hunedoara, Timisoara, Arad, Alba, Sibiu, Caras-Severin (partial)",
  },
  {
    nod: "Roman",
    zona: "RMN",
    arie: "Suceava, Botosani, Iasi, Neamt, Bacau, Vaslui",
  },
  { nod: "Valea Chioarului", zona: "VCH", arie: "Satu Mare, Maramures" },
];

const zones = [
  "BUC",
  "BZU",
  "CLJ",
  "CTA",
  "CRV",
  "MRC",
  "BIH",
  "ORS",
  "RMN",
  "VCH",
];
const deliveryTimes = zones.map((zone, rowIndex) =>
  zones.map((_, colIndex) => ({
    value: rowIndex === colIndex ? "0 - 1" : Math.floor(Math.random() * 3 + 1),
    isHighlight: rowIndex === colIndex,
  }))
);

function Covering() {
  const { t: translate } = useTranslation();

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
      {/* Hero Section */}
      <div
        className="relative w-full h-[60vh] flex items-center justify-center text-center bg-cover bg-center"
        style={{
          backgroundImage: "url('/assets/images/gallery-section.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60" />
        <h1 className="relative text-5xl font-bold text-white">
          {translate("covering_page_title")}
        </h1>
      </div>

      {/* <div className="max-w-4xl bg-white shadow-md rounded-lg overflow-hidden my-10 mx-2 sm:mx-6">
        <table className="w-full text-left">
          <thead>
            <tr className="bg-gray-900 text-white text-sm uppercase tracking-wider">
              <th className="px-6 py-3">NOD ATS</th>
              <th className="px-6 py-3">Zona</th>
              <th className="px-6 py-3">Arie deservita</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {coveringData.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4 font-medium text-gray-900">
                  {item.nod}
                </td>
                <td className="px-6 py-4 text-gray-600">{item.zona}</td>
                <td className="px-6 py-4 text-gray-500">{item.arie}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

      <div className="w-full max-w-5xl bg-white shadow-md rounded-lg overflow-hidden my-10">
        <table className="w-full text-center border border-gray-300">
          <thead>
            <tr className="bg-gray-900 text-white text-sm uppercase tracking-wider">
              <th className="px-4 py-2">Zone</th>
              {zones.map((zone, index) => (
                <th key={index} className="px-4 py-2">
                  {zone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {zones.map((zone, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-100">
                <td className="px-4 py-2 font-medium text-gray-900 bg-gray-200">
                  {zone}
                </td>
                {deliveryTimes[rowIndex].map((item, colIndex) => (
                  <td
                    key={colIndex}
                    className={`px-4 py-2 text-gray-600 ${
                      item.isHighlight ? "bg-gray-200" : ""
                    }`}
                  >
                    {item.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Covering;
