import React from "react";
import Button from "../Button";
import PartnersSlider from "./PartnersSlider";
import { useTranslation } from "react-i18next";

const benefits = [
  {
    titleKey: "hero_benefit_transport_medicines_title",
    descriptionKey: "hero_benefit_transport_medicines_description",
  },
  {
    titleKey: "hero_benefit_supply_chain_title",
    descriptionKey: "hero_benefit_supply_chain_description",
  },
  {
    titleKey: "hero_benefit_storage_distribution_title",
    descriptionKey: "hero_benefit_storage_distribution_description",
  },
];

function CustomHero() {
  const { t: translate } = useTranslation();

  return (
    <div className="w-full min-h-screen text-white">
      <div
        className="w-full min-h-screen flex flex-col lg:flex-row bg-cover bg-center text-white p-10"
        style={{
          backgroundImage: "url('/assets/images/hero-section.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50" />

        <div className="relative z-10 flex flex-col lg:flex-row w-full max-w-7xl mx-auto items-center py-20 gap-10">
          {/* Left Content */}
          <div className="space-y-6 text-center lg:text-left w-full">
            <h1 className="text-5xl lg:text-6xl font-bold leading-tight mb-14">
              {translate("hero_title")}
            </h1>
            <div>
              <p className="text-lg lg:text-xl max-w-md mx-auto lg:mx-0 mb-5">
                {translate("hero_description")}
              </p>

              <Button variant="secondary" href={"/contact"}>
                {translate("contact_us_cta")}
              </Button>
            </div>
          </div>

          {/* Right Side Content */}
          <div className="w-full lg:w-1/2">
            {/* <div className="space-y-4 mt-10 lg:mt-0">
              {benefits.map((benefit, index) => (
                <div
                  key={index}
                  className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg backdrop-blur-lg"
                >
                  <h3 className="text-xl font-semibold">
                    {translate(benefit.titleKey)}
                  </h3>
                  <p className="text-sm">{translate(benefit.descriptionKey)}</p>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
      <PartnersSlider />
    </div>
  );
}

export default CustomHero;
