import React from "react";
import { useNavigate } from "react-router-dom";

function Button({ children, onClick, variant, className, type, href }) {
  const navigate = useNavigate();

  const getVariant = () => {
    if (variant === "secondary") {
      return "bg-white text-black px-6 py-3 rounded-lg mb-12 hover:bg-gray-300 transition";
    }

    return "bg-black text-white px-6 py-3 rounded-lg mb-12 hover:bg-gray-800 transition";
  };

  const handleClick = (e) => {
    if (href) {
      navigate(href);
    } else {
      onClick && onClick();
    }
  };

  return (
    <button
      type={type}
      className={`${getVariant()} ${className} w-fit rounded-lg text-nowrap`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

export default Button;
