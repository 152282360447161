import React from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const ContactUsSection = () => {
  const { t: translate } = useTranslation();

  return (
    <section className="py-16 px-6 lg:px-20 text-white">
      <div
        className="max-w-5xl mx-auto flex flex-col lg:flex-row items-center justify-between 
        p-8 rounded-lg bg-gray-900"
      >
        <div className="mb-6 lg:mb-0">
          <h2 className="text-3xl font-bold">
            {translate("contact_us_title")}
          </h2>
          <p className="text-gray-300 mt-2">
            {translate("contact_us_description")}
          </p>
        </div>

        <Button variant="secondary" href={"/contact"}>
          {translate("contact_us_cta")}
        </Button>
      </div>
    </section>
  );
};

export default ContactUsSection;
