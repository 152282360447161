import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";

const aboutUsData = [
  {
    titleKey: "about_us_transport_title",
    descriptionKey: "about_us_transport_description",
    image: "/assets/images/services-section.jpeg",
  },
  {
    titleKey: "about_us_storage_title",
    descriptionKey: "about_us_storage_description",
    image: "assets/images/services-section-storage3.jpg",
  },
  {
    titleKey: "about_us_traceability_title",
    descriptionKey: "about_us_traceability_description",
    image: "assets/images/services-section-traceability.jpeg",
  },
];

const AboutUsSection = () => {
  const { t: translate } = useTranslation();

  return (
    <>
      <section className="py-20 text-center px-6 lg:px-20 bg-white">
        <h2 className="text-4xl font-bold mb-4">
          {translate("about_us_title")}
        </h2>
        <p className="max-w-3xl mx-auto text-lg mb-6">
          {translate("about_us_description")}
        </p>

        <Button variant="primary" href={"/about-us"} className="mb-12">
          {translate("about_us_cta")}
        </Button>
        <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:h-[520px]">
          {aboutUsData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col-reverse lg:flex-col-reverse items-center w-full sm:w-3/4 lg:w-auto group transition-all duration-500"
            >
              <div className="relative bg-white rounded-lg overflow-hidden shadow-lg w-full sm:w-80 md:w-full lg:w-72 h-96 lg:h-96 lg:group-hover:w-80 lg:group-hover:h-[500px] transition-all duration-500">
                <img
                  src={item.image}
                  alt={translate(item.titleKey)}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center lg:opacity-0 lg:group-hover:opacity-100 lg:group-hover:delay-300 transition-opacity duration-500">
                  <p className="text-white text-lg font-medium p-4 transform scale-90 lg:opacity-0 lg:group-hover:scale-100 lg:group-hover:opacity-100 transition-all duration-500">
                    {translate(item.descriptionKey)}
                  </p>
                </div>
              </div>
              <h3 className="text-xl font-semibold my-4">
                {translate(item.titleKey)}
              </h3>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AboutUsSection;
